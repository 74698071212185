import React from 'react';
import '../App.css';
import HeaderSection from '../components/Header';
import FooterSection from '../components/Footer';
import PrivacyPolicySec from '../components/privacypolicy';
import BreadcrumbSection from '../components/BreadCrumb';
import privacybc from '../images/privacy.png';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


const Process = () => {



    return (
      <>
        <HeaderSection />
        <Helmet>
          <title>Privacy Policy | The App Design</title>
          <meta name="description" content="Explore The App Design Privacy Policy to learn how we collect, use, and protect your personal information, ensuring transparency and trust in every interaction." />
          <link rel="canonical" href="https://theappdesign.com/privacy-policy" />

        </Helmet>
        <div>
       <BreadcrumbSection
        heading="The App Design - Privacy Policy"
        description="A place where talent and ambition can soar. Unleash your creativity and let your talents take center stage.  <br />When you work with us, you step into a world where your passion meets our purpose."
        buttonText="Learn More"
        buttonLink="#"
        backgroundImage={privacybc}
      />
        </div>
        <PrivacyPolicySec />
        <FooterSection />

        
      </>
    );
  };
  
  export default Process;
