import React from 'react';
import acquire from '../images/acquire-app.png';


const AcquireAppSection = () => {
  return (
    <section className="acquire-app-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 acquire-app-image">
            <img src={acquire} alt="Descriptive Image Text" />
          </div>
          <div className="col-lg-6 acquire-app-text">
            <h2>Reliable Mobile App Development Services in the USA</h2>
            <p>
            We create mobile applications customized to fulfill your business needs. Ranging from tailored 
            solutions to specific native iOS and Android mobile applications, our mobile app development 
            services ensure your apps are both functional and user-friendly.
            </p>
            <p>
            Our purpose is to build apps that truly engage your audience. From conceiving to deployment, we make 
            sure every mobile app we develop not only serves its purpose but also helps your business grow and 
            stand out in a competitive market.
            </p>
            <button data-bs-toggle='modal' data-bs-target='#popupForm' className="acquire-app-button">Get Started</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AcquireAppSection;
