import React from 'react';
import '../whychoose.css'; // Optional for custom styling
import mobileappimg1 from '../images/1----.webp';
import mobileappimg3 from '../images/3--------.webp';
import mobileappimg2 from '../images/2-----.webp';

const WhyChooseAppDev = () => {
  const services = [
    {
      image: mobileappimg2,
    },
    {
      image: mobileappimg1,
    },
    {
      image: mobileappimg3,
    },
  ];

  return (
    <section className="why-choose-app-dev">
      <div className="container">
        <div className="text-center">
          {/* Heading */}
          <h2 className="fw-bold mb-3">Our Dedication to Safeguarding Your Data</h2>
          <p className="mb-4">
            At The App Design, we are committed to protecting your privacy and safeguarding<br />
            your data with the highest standards of security and transparency.
          </p>
        </div>

        {/* Services */}
        <div className="row justify-content-center">
          {services.map((service, index) => (
            <div className="col-md-4 col-sm-6 mb-4" key={index}>
              <div className="service-item text-center">
                <img src={service.image} alt={service.title} className="img-fluid mb-2" />
                {/* <p
                  className="service-title"
                  dangerouslySetInnerHTML={{ __html: service.title }}
                ></p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseAppDev;