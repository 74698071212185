import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../CreativeMinds.css';


// Import your icons here
import ProductDesignIcon from '../images/high-quality.gif';
import DigitalServicesIcon from '../images/Strategic Partnerships.gif';
import InnovativeThinkingIcon from '../images/vision.gif';
import ContentEngineeringIcon from '../images/content-engineering-icon.png';

const CreativeMindsComponent = () => {
  return (
    <section className="creative-minds-container py-5">
      <Container>
        <div className="creative-minds-heading text-center mb-5">
          <h2>The App Design Guarantees</h2>
        </div>
        <Row className="creative-minds-row text-center">
          <Col md={4} className="creative-minds-col ">
            <img src={ProductDesignIcon} alt="Product Design" className="creative-minds-icon img-fluid" />
            <h5 className="creative-minds-title mt-3">High-Quality Practices</h5>
            <p className="creative-minds-description">We guarantee to use high-quality practices to design apps that work well and look great, 
              ensuring a seamless experience that attracts the user's attention and retains it to your brand.</p>
          </Col>
          <Col md={4} className="creative-minds-col">
            <img src={DigitalServicesIcon} alt="Digital Services" className="creative-minds-icon img-fluid" />
            <h5 className="creative-minds-title mt-3">Strategic Partnerships</h5>
            <p className="creative-minds-description">We build smart partnerships to create better app designs and deliver great results, 
              leveraging the collective expertise of our network to achieve exceptional outcomes.</p>
          </Col>
          <Col md={4} className="creative-minds-col">
            <img src={InnovativeThinkingIcon} alt="Innovative Thinking" className="creative-minds-icon img-fluid" />
            <h5 className="creative-minds-title mt-3">Innovative Thinking</h5>
            <p className="creative-minds-description">We use new and creative ideas to design apps that are smart and different, pushing the 
              boundaries of mobile technology to deliver cutting-edge solutions.</p>
          </Col>
          
        </Row>
      </Container>
    </section>
  );
};

export default CreativeMindsComponent;
