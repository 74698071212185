import React from 'react';

const UniqueSection = () => {
  return (
    <section className="unique-section">
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="d-flex justify-content-between align-items-center unique-section-container">
              <p className="unique-section-text mb-0">
                Kickstart your <a href="tel:+15515543283" className="unique-section-link">Mobile App</a> project today!
              </p>
              <button data-bs-toggle='modal' data-bs-target='#popupForm' className="btn btn-primary unique-section-button">Get a Quote</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UniqueSection;

