import React from "react";
import '../terms.css';

const TermsOfUse = () => {
  return (
  
    <div className="terms-container">
     
      {/* Main Content Section */}
      <main className="terms-content container">

        {/* Definitions Section */}
        <section className="terms-section mb-4">
          <h2>Definitions</h2>
          <p>
            <strong>"The App Design"</strong> or <strong>"we"</strong> or <strong>"us"</strong> refers to The App Design, 
            a development and design agency, including its officers, directors, employees, agents, and affiliates.
          </p>
          <p>
            <strong>"Website"</strong> refers to The App Design's website located at theappdesign.com, including any 
            subdomains, mobile versions, and related content and functionality.
          </p>
          <p>
            <strong>"Services"</strong> refers to the development, design, and other services provided by The App Design, 
            as well as any related software, documentation, and other materials provided through your use of the Website’s Contacts form.
          </p>
        </section>

        {/* General Section */}
        <section className="terms-section mb-4">
          <h2>General</h2>
          <p>
            These Terms of Use ("Terms") govern your access to and use of our Website, and ordering of services and 
            products, provided by The App Design ("Services"). By accessing or using our Services, you agree to be bound by these Terms.
          </p>
          <p>
            Please read these Terms carefully. If you do not agree to these Terms, you must not access or use our 
            Services through our Website.
          </p>
        </section>

        {/* Eligibility Section */}
        <section className="terms-section mb-4">
          <h2>Eligibility</h2>
          <p>
            You must be at least 18 years of age to use our Services, unless you are between the ages of 14 and 18 
            (or the age of majority established by law in the user's jurisdiction) and are under the supervision of a 
            parent or legal guardian who has agreed to these Terms.
          </p>
          <p>
            Individuals under the age of 14 are prohibited from requesting our Services. By accessing or using our 
            Services, you represent and warrant that you meet the above requirements and are capable of entering 
            into a legally binding agreement.
          </p>
        </section>

        {/* License to Use Section */}
        <section className="terms-section mb-4">
          <h2>License to Use the Website</h2>
          <p>
            Subject to these Terms, The App Design grants you a limited, non-exclusive, non-transferable, and 
            revocable license to access and use the Website solely for your personal and non-commercial use.
          </p>
        </section>

        {/* Restrictions Section */}
        <section className="terms-section mb-4">
          <h2>Restrictions</h2>
          <p>You agree not to use the Website in any manner that violates these Terms or any applicable laws, rules, or regulations. Without limiting the foregoing, you agree not to:</p>
          <ul>
            <li>Engage in any conduct that interferes with or disrupts the Website, including but not limited to distributing viruses, creating an unreasonable load on the Website, or using any automated means to access or collect data from the Website;</li>
            <li>Use any robot, spider, scraper, or other automated means to access or collect data from the Website for any purpose without our express written permission;</li>
            <li>Attempt to gain unauthorized access to any portion of the Website, other accounts, computer systems, or networks connected to the Website, through hacking, password mining, or any other means;</li>
            <li>Use any manual or automated software, devices, or other processes to "crawl" or "spider" any page of the Website;</li>
            <li>Introduce any viruses, Trojan horses, worms, time bombs, cancelbots, or other computer programming routines that may damage, interfere with, intercept, or expropriate any system, data, or personal information;</li>
            <li>Modify, adapt, translate, or create derivative works based on the Website or any portion thereof;</li>
            <li>Remove, obscure, or alter any copyright, trademark, or other proprietary rights notice or legends that appear on the Website;</li>
            <li>Use the Website for any commercial purpose without our express written permission.</li>
          </ul>
          </section>

        {/* Third-Party Links Section */}
        <section className="terms-section mb-4">
          <h2>Third-Party Links</h2>
          <p>
            You are solely responsible for and assume all risks arising from your use of any third-party websites, 
            services, or resources. The App Design is not responsible for the content, products, or services available 
            from such third parties.You are solely responsible for and assume all risks arising from your use of any 
            third-party websites, services, or resources.
          </p>
        </section>

        {/* Intellectual Property Section */}
        <section className="terms-section mb-4">
          <h2>Intellectual Property</h2>
          <p>
            All content on our website, including but not limited to text, graphics, logos, images, and software, 
            is the property of The App Design or its licensors and is protected by intellectual property laws. 
            You may not use any of our content without our prior written consent.
          </p>
          <p>
            You have only the right to view the Website and use the Website to order the Services. As a condition 
            of using the Website, you warrant to Us that You will not use it for commercial purposes or any other 
            purposes that are illegal or prohibited by these Terms.
          </p>
          <p>
            Such use of the Website does not include any rights to: (a) sell or commercially exploit any part of 
            the Website; (b) copy, distribute, publicly use, and publicly display any part of the Website; (c) modify 
            any part of the Website or remove notices of titles; or (d) reconstruct or extract the source code of this software.
          </p>
        </section>

        {/* Disclaimer of Warranties Section */}
        <section className="terms-section mb-4">
          <h2>Disclaimer of Warranties</h2>
          <p>
            The Website is provided "as is" and "as available" without warranties of any kind, either express or implied, 
            including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, 
            and non-infringement. The App Design does not warrant that the Services will be uninterrupted, error-free, 
            secure, or free from viruses or other harmful components.
          </p>
          <p>
            WE EXPRESSLY DISCLAIM ANY AND ALL CONDITIONS, REPRESENTATIONS, WARRANTIES OR OTHER TERMS, WHETHER EXPRESS OR IMPLIED. 
            YOU ACKNOWLEDGE THAT WE DO NOT WARRANT THAT THE WEBSITE OR APP WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE AND YOU 
            FURTHER ACKNOWLEDGE THAT WE DO NOT WARRANT THAT THE ACCESS TO THE SERVICE(S), WHICH IS PROVIDED OVER INTERNET AND VARIOUS 
            TELECOMMUNICATIONS NETWORKS, ALL OF WHICH ARE BEYOND OUR CONTROL, WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR FREE 
            FROM VIRUSES OR OTHER MALICIOUS SOFTWARE.
          </p>
        </section>

        {/* Governing Law and Jurisdiction Section */}
        <section className="terms-section mb-4">
          <h2>Governing Law and Jurisdiction</h2>
          <p>
            These Terms shall be governed by and construed in accordance with the laws of the US, without regard to its 
            conflict of law provisions. You agree to submit to the exclusive jurisdiction of the courts located in the US to 
            resolve any legal matter arising from these Terms.
          </p>
        </section>

        {/* Changes to These Terms Section */}
        <section className="terms-section mb-4">
          <h2>Changes to These Terms</h2>
          <p>
            The App Design reserves the right, in its sole discretion, to modify or replace these Terms at any time 
            without prior notice to users. The updated version of the Terms will be posted on our website and it is 
            your responsibility to review the Terms periodically. Your continued access to or use of our Services after 
            any revisions become effective constitutes your acceptance of the new Terms. If you do not agree to the new Terms, 
            you must stop using the Services.
          </p>
        </section>

        {/* Miscellaneous Section */}
        <section className="terms-section mb-4">
          <h2>Miscellaneous</h2>
          <p>
            These Terms constitute the entire agreement between you and The App Design concerning your access to and 
            use of the Website, and if any provision of these Terms is found to be unenforceable or invalid, that provision 
            will be limited or eliminated to the minimum extent necessary so that the Terms will otherwise remain in full force and effect.
          </p>
          <p>
            The failure of The App Design to exercise or enforce any right or provision of these Terms shall not constitute 
            a waiver of such right or provision.
          </p>
          <p>
          If you have any questions or concerns about these terms of use, please contact us at <a href="mailto:info@theappdesign.com">info@theappdesign.com</a>. We will make every effort 
          to respond as soon as possible, but please note that it may take up to 30 days to receive a response.
          </p>
        </section>

      

      </main>
    </div>

  );
};

export default TermsOfUse;
