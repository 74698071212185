import React from 'react';
import '../App.css';
import HeaderSection from '../components/Header';
import FooterSection from '../components/Footer';
import UniqueSection from '../components/unique';
import ContactSection from '../components/form';
import FAQSection from '../components/faqs';
import StepSection from '../components/steps';
import BreadcrumbSection from '../components/BreadCrumb';
import aboutusBc from '../images/process.png';

import { Helmet } from 'react-helmet-async';

import { Link } from 'react-router-dom';
import PopupForm1 from '../components/PopupForm';


const Process = () => {

// ******************************** Start FAQ Section ******************************
const faqData = [

  {
    question: "How much does it cost to design a mobile app?",
    answer: "Designing a mobile app can vary from $5,000 to $50,000 depending on several factors. A straightforward mobile app will usually be cheaper compared to high-end features."
  },
  {
    question: "How can I design a mobile app?",
    answer: "A mobile app can be designed, defining its purpose, wireframes created, UI/UX designed, the app developed, and finally, it can be tested. Expert teams work effectively and bring even higher-quality results."
  },
  {
    question: "What is mobile app UI UX design?",
    answer: "This includes creating attractive screens, making it easy for the user to navigate through the application and enjoy using it. Mobile app UI/UX design enables effective user interface (UI) design and overall user experience (UX) enhancement."
  },
  {
    question: "Who designs phone apps?",
    answer: "Designers design apps and developers work in app development. Usually, app-designing teams consist of designers and developers. Designers only focus on the UI and UX to be simplistic, while developers make the whole functionality of the application, whereby catering for programming and coding."
  },
  {
    question: "How to design UI UX for an Android app?",
    answer: "To design UI/UX for an Android app, create wireframes, follow Design principles, and test for usability across devices."
  }
];

// ******************************** End FAQ Section ******************************



    return (
      <>
        <HeaderSection />
        <Helmet>
          <title>Our Process | The App Design</title>
          <meta name="description" content="Discover our streamlined process: from initial consultation to project completion, we ensure efficiency, transparency, and good results tailored to your needs." />
          <link rel="canonical" href="https://theappdesign.com/our-process" />
        </Helmet>
        <div>
      <BreadcrumbSection
        heading="Our Process"
        description="We deliver outstanding digital experiences with a user-centric design<br/> to ensure our applications' immersion and ease of use."
        buttonText="Get Started"
        buttonLink="#"
        backgroundImage= {aboutusBc}
      />
    </div>
        <StepSection />
        <UniqueSection />


        <div>
         <FAQSection faqData={faqData} />
        </div>
        <ContactSection />
        <FooterSection />

        <PopupForm1/>

      </>
    );
  };
  
  export default Process;
