import React, { useState } from "react";
import "../leftsec.css";
import missiontb from '../images/mission.png';


const TabSection = () => {
  const [activeTab, setActiveTab] = useState("mission");

  const renderContent = () => {
    if (activeTab === "mission") {
      return (
        <p>
          We devote ourselves to developing incredible, user-desired applications 
          that solve problems in the world. Also, we work towards simplifying everyday 
          tasks, making the digital experience considerably better. Well-strung with 
          technology, our solutions are capable of driving innovation and enabling 
          users and businesses to accomplish their targets efficiently.
        </p>
      );
    }
    if (activeTab === "vision") {
      return (
        <p>
          Our dream is to emerge as a global leader in app development, renowned for 
          its intuitive and impactful applications. We will redefine the way people 
          interact with technology and do things differently ever more than before 
          around definition, function, and satisfaction while entering the digital 
          world.
        </p>
      );
    }
  };

  return (
    <section className="tab-section">
      <div className="container">
        <div className="row align-items-center">
          {/* Image Section */}
          <div className="col-md-6">
            <div className="image-container">
              <img
                src= {missiontb} // Replace with your image URL
                alt="E-Book Services"
              />
            </div>
          </div>

          {/* Content Section */}
          <div className="col-md-6">
            <div className="content-container">
              <h2> 
              Our Mission & Vision </h2> 
              <p>
              Exceptional apps are born from a perfect balance of innovation, strategy, and collaboration. 
              Our process is designed to ensure every project reflects your brand’s uniqueness and meets 
              the highest standards. 
              </p>
              <div className="tabs">
                {/* Mission Tab */}
                <button
                  className={`tab ${activeTab === "mission" ? "active" : ""}`}
                  onClick={() => setActiveTab("mission")}
                >
                  <i
                    className={`icon fa ${
                      activeTab === "mission"
                        ? "fa-bullseye active-icon"
                        : "fa-bullseye"
                    }`}
                  ></i>{" "}
                  Mission
                </button>
                {/* Vision Tab */}
                <button
                  className={`tab ${activeTab === "vision" ? "active" : ""}`}
                  onClick={() => setActiveTab("vision")}
                >
                  <i
                    className={`icon fa ${
                      activeTab === "vision"
                        ? "fa-eye active-icon"
                        : "fa-eye"
                    }`}
                  ></i>{" "}
                  Vision
                </button>
              </div>
              <div className="tab-content">{renderContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TabSection;