import React from 'react';
import Step01 from "../images/Step01.webp";
import Step02 from "../images/Step05.webp";
import Step03 from "../images/Step03.webp";
import Step04 from "../images/Step04.webp";
import Step05 from "../images/Step02.webp";
import Step06 from "../images/Step06.webp";



const StepSection = () => {
  return (
    <section className="step-section">
      <div className="container">
        {/* Section Heading and Description */}
        <div className="section-header text-center">
          <h2>Helping You Tackle Challenges and<br/> Reach Your Goals Effortlessly</h2>
          <p>We're here to simplify your journey. Let's break down complex tasks into manageable steps. Together,<br/> 
          we'll overcome obstacles and celebrate successes. Your goals are our priority, let's make them a reality.</p>
        </div>

        {/* Step 1 */}
        <div className="row align-items-center">
          {/* Left Content */}
          <div className="col-12 col-md-5 order-2 order-md-1 step-content text-center text-md-end">
            <h2>Launch a Complex Project</h2>
            <p>To launch a complex app project, we define SMART goals, assemble a skilled team,
              and create a detailed project plan. We secure resources, establish effective communication, 
              and foster collaboration. This ensures a solid foundation for project success.</p>
          </div>
          {/* Center Step */}
          <div className="col-12 col-md-2 order-1 step-box">
            STEP
            <span>01</span>
          </div>
          {/* Right Image */}
          <div className="col-12 col-md-5 order-3 step-image">
           <img src= {Step01} alt="Placeholder Image" />
          </div>
        </div>

        {/* Step 2 */}
        <div className="row align-items-center">
          {/* Center Step */}
          <div className="col-12 col-md-2 order-1 order-md-2 step-box">
            STEP
            <span>02</span>
          </div>
          {/* Right Content */}
          <div className="col-12 col-md-5 order-2 order-md-3 step-content text-center text-md-start">
            <h2>Utilize Specific Tech and Skill Sets</h2>
            <p>We leverage cutting-edge technologies to create innovative app solutions. Our team stays updated with industry 
              trends and invests in necessary tools. We foster a culture of continuous learning to unlock new opportunities 
              and deliver exceptional digital experiences.</p>
          </div>
          {/* Left Image */}
          <div className="col-12 col-md-5 order-3 order-md-1 step-image">
            <img src= {Step02} alt="Placeholder Image" />
          </div>
        </div>

        {/* Step 3 */}
        <div className="row align-items-center">
          {/* Left Content */}
          <div className="col-12 col-md-5 order-2 order-md-1 step-content text-center text-md-end">
            <h2>Understand the Process</h2>
            <p>We analyze each stage of app development to identify potential challenges and develop effective strategies. 
              By conducting thorough user research, we create user-centric designs that meet client and user needs.</p>
          </div>
          {/* Center Step */}
          <div className="col-12 col-md-2 order-1 step-box">
            STEP
            <span>03</span>
          </div>
          {/* Right Image */}
          <div className="col-12 col-md-5 order-3 order-md-1 step-image">
            <img src= {Step03} alt="Placeholder Image" />
          </div>
        </div>

        {/* Step 4 */}
        <div className="row align-items-center">
          {/* Center Step */}
          <div className="col-12 col-md-2 order-1 order-md-2 step-box">
            STEP
            <span>04</span>
          </div>
          {/* Right Content */}
          <div className="col-12 col-md-5 order-2 order-md-3 step-content text-center text-md-start">
            <h2>Lead in a Unique Niche</h2>
            <p>We differentiate ourselves by focusing on specific niches and conducting in-depth market research. We develop a 
              strong value proposition and build strong client relationships. By focusing on a specific niche, we position ourselves 
              as industry leaders.</p>
          </div>
          {/* Left Image */}
          <div className="col-12 col-md-5 order-3 order-md-1 step-image">
            <img src= {Step04} alt="Placeholder Image" />
          </div>
        </div>

        {/* Step 5 */}
        <div className="row align-items-center">
          {/* Left Content */}
          <div className="col-12 col-md-5 order-2 order-md-1 step-content text-center text-md-end">
            <h2>Focus on Product Goals</h2>
            <p>We prioritize product goals to deliver exceptional value. We define core features, target audience, and desired outcomes. 
              We develop a product roadmap, regularly assess performance, and gather user feedback. By focusing on product goals, we 
              ensure high-quality, innovative, and user-centric apps.</p>
          </div>
          {/* Center Step */}
          <div className="col-12 col-md-2 order-1 step-box">
            STEP
            <span>05</span>
          </div>
          {/* Right Image */}
          <div className="col-12 col-md-5 order-3 order-md-1 step-image">
            <img src= {Step05} alt="Placeholder Image" />
          </div>
        </div>

        {/* Step 6 */}
        <div className="row align-items-center">
          {/* Center Step */}
          <div className="col-12 col-md-2 order-1 order-md-2 step-box">
            STEP
            <span>06</span>
          </div>
          {/* Right Content */}
          <div className="col-12 col-md-5 order-2 order-md-3 step-content text-center text-md-start">
            <h2>Save Time and Energy</h2>
            <p>We optimize workflows to maximize efficiency. We automate repetitive tasks, implement time management techniques, 
              and foster a culture of continuous improvement. This saves time and energy, reduces stress, and improves work-life balance.</p>
          </div>
          {/* Left Image */}
          <div className="col-12 col-md-5 order-3 order-md-1 step-image">
            <img src= {Step06} alt="Placeholder Image" />
          </div>
        </div>

      </div>
    </section>
  );
}

export default StepSection;
