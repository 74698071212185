import React from 'react';
import Slider from 'react-slick'; // Import React Slick
import 'slick-carousel/slick/slick.css'; // Slick core CSS
import 'slick-carousel/slick/slick-theme.css'; // Slick theme CSS
import mobile1 from '../images/Group 1.png';
import mobile2 from '../images/Untitled-1.webp';
import mobile3 from '../images/rfs.webp';
import mobile4 from '../images/creative.webp';

const BannerSection = ({ updatePopupTitle }) => {
  const settings = {
    dots: false, // Remove dots
    infinite: true, // Enable infinite scrolling
    speed: 1000, // Transition speed
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set autoplay delay
    arrows: false, // Disable arrows
    pauseOnHover: true, // Pause autoplay on hover
  };

  return (
    <section className="banner-section">
      <div className="overlay"></div>
      <div className="container">
        <div className="row align-items-center">
          {/* Content Section */}
          <div className="col-lg-8">
            <h1>Mobile Application<br/>
            Design Services</h1>
            <p>
              At The App Design, we offer Mobile App Design Services that bring your ideas to life.
              Our scalable, user-friendly apps for iOS and Android help your business grow and connect
              with customers.
            </p>
            <button data-bs-toggle='modal' data-bs-target="#popupForm" className="btn btn-primary">Build Your Dream App</button>
          </div>

          {/* Image Section */}
          <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end">
            <div className="screenshot-carousel">
              <Slider {...settings}>
                <img className="img-fluid" src={mobile1} alt="Mobile 1" />
                <img className="img-fluid" src={mobile2} alt="Mobile 2" />
                <img className="img-fluid" src={mobile3} alt="Mobile 3" />
                <img className="img-fluid" src={mobile4} alt="Mobile 4" />
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
