import React from "react";
import '../AppDevValues.css'; // Assuming you will save the CSS in this file
import Creativity from "../images/creative.png";
import Passion from "../images/Passion.png";
import Discipline from "../images/Discipline.png";
import Flexibility from "../images/Flexibility.png";
import TeamWork from "../images/Team Work.png";
import Quality from "../images/Quality.png";



const AppDevValues = () => {
  return (
    <section className="app-dev-values-section-unique">
      <div className="container">
        <div className="text-center mb-5">
          <h2 className="app-dev-values-title">App Dev Values</h2>
          {/* <p className="app-dev-values-description">
            We bring products to life with magical animation. Whether you're a startup or a brand that is struggling to make a mark.
          </p> */}
        </div>
        <div className="row text-center">
          <div className="col-md-4">
            <div className="card app-dev-values-card">
              <div className="card-body d-flex align-items-center">
                <div className="icon app-dev-values-icon">
                  <img src= {Creativity} alt="Creativity Icon" className="app-dev-values-icon-image"/>
                </div>
                <div className="text-content">
                  <h5 className="card-title app-dev-values-card-title">Creativity</h5>
                  <p className="card-text app-dev-values-card-text">Our team's creativity fuels innovation, enabling us to create solutions that stand out and drive success.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card app-dev-values-card">
              <div className="card-body d-flex align-items-center">
                <div className="icon app-dev-values-icon">
                  <img src= {Passion} alt="Passion Icon" className="app-dev-values-icon-image"/>
                </div>
                <div className="text-content">
                  <h5 className="card-title app-dev-values-card-title">Passion</h5>
                  <p className="card-text app-dev-values-card-text">Our team's passion drives us to go above and beyond, delivering exceptional results with dedication.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card app-dev-values-card">
              <div className="card-body d-flex align-items-center">
                <div className="icon app-dev-values-icon">
                  <img src= {Discipline} alt="Fun Icon" className="app-dev-values-icon-image"/>
                </div>
                <div className="text-content">
                  <h5 className="card-title app-dev-values-card-title">Discipline</h5>
                  <p className="card-text app-dev-values-card-text">Our team's discipline ensures we stay focused, meet deadlines, and consistently deliver high-quality results.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card app-dev-values-card">
              <div className="card-body d-flex align-items-center">
                <div className="icon app-dev-values-icon">
                  <img src= {Flexibility} alt="Fun Icon" className="app-dev-values-icon-image"/>
                </div>
                <div className="text-content">
                  <h5 className="card-title app-dev-values-card-title">Flexibility</h5>
                  <p className="card-text app-dev-values-card-text">Our team's flexibility allows us to adapt to changing needs and challenges, providing solutions in any situation.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card app-dev-values-card">
              <div className="card-body d-flex align-items-center">
                <div className="icon app-dev-values-icon">
                  <img src= {TeamWork} alt="Fun Icon" className="app-dev-values-icon-image"/>
                </div>
                <div className="text-content">
                  <h5 className="card-title app-dev-values-card-title">Team Work</h5>
                  <p className="card-text app-dev-values-card-text">Our team works together, combining our strengths to achieve common goals and deliver great results.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card app-dev-values-card">
              <div className="card-body d-flex align-items-center">
                <div className="icon app-dev-values-icon">
                  <img src= {Quality} alt="Fun Icon" className="app-dev-values-icon-image"/>
                </div>
                <div className="text-content">
                  <h5 className="card-title app-dev-values-card-title">Quality</h5>
                  <p className="card-text app-dev-values-card-text">Our team is committed to maintaining the highest quality in everything we do, ensuring excellent results every time.</p>
                </div>
              </div>
            </div>
          </div>
          {/* Repeat for other cards as needed */}
        </div>
      </div>
    </section>
  );
};

export default AppDevValues;
