import React, { useState } from "react";
import '../Mobileapp.css'; // Make sure to include your CSS file
import apppic from '../images/mobileapp.png';

const MobileAppsSection = () => {
  const [activeIndex, setActiveIndex] = useState(1); // Track which accordion item is active

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle between showing and hiding
  };

  return (
    <section className="mobile-apps-section">
      <div className="container">
      <h2 className="mobile-apps-heading">Types of Mobile Apps We Build</h2>
        <div className="row align-items-center">
          <div className="col-md-6 text-center">
            <img src= {apppic} alt="Mobile Apps" className="app-image" />
          </div>
          <div className="col-md-6">
            {/* <h2>Types of mobile apps we build.</h2> */}
            <div className="accordion" id="appTypesAccordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={`accordion-button ${activeIndex === 1 ? '' : 'collapsed'}`}
                    onClick={() => toggleAccordion(1)}
                  >
                    Native Apps
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className={`accordion-collapse collapse ${activeIndex === 1 ? 'show' : ''}`}
                  aria-labelledby="headingOne"
                >
                  <div className="accordion-body">
                  We develop strong native apps for both iOS and Android on the one hand and, on the other hand, 
                  tailor a delivery of app development complementing the business requirements and security with 
                  all possible platform-specific facilities, thus creating a highly taxonomic and intuitive 
                  experience, opening new business avenues for user engagement.                  
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className={`accordion-button ${activeIndex === 2 ? '' : 'collapsed'}`}
                    onClick={() => toggleAccordion(2)}
                  >
                    Cross-Platform Apps
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className={`accordion-collapse collapse ${activeIndex === 2 ? 'show' : ''}`}
                  aria-labelledby="headingTwo"
                >
                  <div className="accordion-body">
                  As an experienced App Design Agency, we create cross-platform apps that are both cost-effective and easy to maintain. 
                  These apps work smoothly across multiple devices, combining the advantages of native and web technologies. With our 
                  approach, we ensure your app performs flawlessly and provides a consistent user experience on every platform. This way,
                  you can reach a wider audience while maintaining high quality and functionality.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className={`accordion-button ${activeIndex === 3 ? '' : 'collapsed'}`}
                    onClick={() => toggleAccordion(3)}
                  >
                    Hybrid Apps
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className={`accordion-collapse collapse ${activeIndex === 3 ? 'show' : ''}`}
                  aria-labelledby="headingThree"
                >
                  <div className="accordion-body">
                  As a provider of Mobile Apps Design Services, we build hybrid apps that combine the best of native and web technologies. 
                  These apps work on multiple platforms using one codebase and can access device features. While they are cost-effective and 
                  flexible, they may not perform as well as native apps, making them ideal for businesses looking for a good balance of 
                  features and reach.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className={`accordion-button ${activeIndex === 4 ? '' : 'collapsed'}`}
                    onClick={() => toggleAccordion(4)}
                  >
                    Wearables Apps
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className={`accordion-collapse collapse ${activeIndex === 4 ? 'show' : ''}`}
                  aria-labelledby="headingFour"
                >
                  <div className="accordion-body">
                  We create wearable apps for devices like smartwatches and fitness trackers. These apps help track health data, show notifications, 
                  and connect easily with your phone. They make it easier to stay fit, get quick updates, and access important information on the go.
                  </div>
                </div>
              </div>
            </div>
            <button data-bs-toggle='modal' data-bs-target="#popupForm" className="acquire-app-button">Get Started</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileAppsSection;
