import React from 'react';
import '../RightSec.css'; // Assuming you'll use a separate CSS file
import righttb from '../images/right.png';

const RightSec = () => {
  return (
    <section className="rightsec-section">
      <div className="container">
        <div className="row align-items-center">
          {/* Content Section */}
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            <h2 className="rightsec-title">
            Our Purpose and Philosophy
            </h2>
            <p className="rightsec-description">
            At The App Design, we turn ideas into design applications that are user-centric, innovative, 
            and real-world problem solvers. We make the daily lives of humans better, empower businesses, 
            and inspire creation with the help of state-of-the-art technology.
            </p>
            <p className="rightsec-description">
            We aspire to maintain a state between simple and functional elegance. At the same time, our 
            approach is entirely visionary design, seamless user experience, and solid engineering that 
            will deliver applications that are crafted for a more productive, pleasurable, and easier life. 
            For us, success is always going to be about the solutions that touch people and last truly through 
            the ages.
            </p>
            <button data-bs-toggle='modal' data-bs-target='#popupForm' className="acquire-app-button">Get Started</button>
          </div>

          {/* Image Section */}
          <div className="col-lg-6 col-md-12 text-center">
            <img
              src= {righttb}
              alt="App Screens"
              className="img-fluid rightsec-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RightSec;
