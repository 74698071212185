import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';  // Bootstrap CSS
import 'bootstrap-icons/font/bootstrap-icons.css';  // Bootstrap Icons
import 'owl.carousel/dist/assets/owl.carousel.min.css';  // Owl Carousel CSS
import 'owl.carousel/dist/assets/owl.theme.default.min.css';  // Owl Carousel Theme
import { Link } from "react-router-dom";

import logoimage from '../images/footerlogo.png'

const Footer = () => {
  return (
    <section className="footer-section bg-primary text-white">
      <footer className="footer py-5">
        <div className="container">
          <div className="row">
            {/* Logo and Description */}
            <div className="col-lg-4 col-md-6 mb-4">
            <h3 className="footer-logo mb-3">
            <Link to="/">
              <img src={logoimage} alt="The App Design" style={{ maxWidth: "100px" }} />
            </Link>
            </h3>
              <p>
              The App Design is a Leading mobile app development agency specializing in creating custom, 
              user-friendly apps that blend design and functionality to help businesses engage users and achieve growth.
              </p>
            </div>

            {/* Quick Links */}
            <div className="col-lg-2 col-md-6 mb-4">
              <h5 className="mb-3">Quick Links</h5>
              <ul className="list-unstyled">
                <li><a href="/" className="text-white text-decoration-none">Home</a></li>
                <li><a href="services" className="text-white text-decoration-none">Services</a></li>
                <li><a href="about-us" className="text-white text-decoration-none">About Us</a></li>
                <li><a href="our-process" className="text-white text-decoration-none">Our Process</a></li>
              </ul>
            </div>

            {/* Contact Us */}
              <div className="col-lg-4 col-md-6 mb-4">
                <h5 className="mb-3">Contact Us</h5>
                <p>
                  <i className="bi bi-geo-alt-fill me-2"></i>
                  <a 
                    href="https://maps.google.com/?q=43+3rd+Ave+2nd+Floor,+Edison,+NJ+08837" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-white"
                  >
                    43 3rd Ave 2nd Floor, Edison, NJ 08837
                  </a>
                </p>
                <p>
                  <i className="bi bi-telephone-fill me-2"></i>
                  <a href="tel:+15515543283" className="text-white">
                  +1 (551) 554-3283 
                  </a>
                </p>
                <p>
                  <i className="bi bi-envelope-fill me-2"></i>
                  <a href="mailto:info@theappdesign.com" className="text-white">
                    info@theappdesign.com
                  </a>
                </p>
              </div>


            {/* Follow Us */}
            <div className="col-lg-2 col-md-6 mb-4">
              <h5 className="mb-3">Follow Us</h5>
              <div className="d-flex">
                <a href= "https://www.facebook.com/TheAppDesign/" className="text-white me-3"><i className="bi bi-facebook"></i></a>
                {/* <a href="https://www.instagram.com/webdesignmania_us/" className="text-white me-3"><i className="bi bi-instagram"></i></a> */}
                <a href= "https://www.linkedin.com/company/the-app-design/" className="text-white"><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>
          



          <hr className="border-white my-4" />
          <div className="text-center">
            <p className="mb-1">
              &copy; 2024 <strong>The App Design</strong>. Powered by RFS
            </p>
            <p>
              <a href="/terms-and-conditions" className="text-white text-decoration-none me-2">Terms & Conditions</a>|
              <a href="/privacy-policy" className="text-white text-decoration-none ms-2">Privacy Policy</a>
            </p>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
